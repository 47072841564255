input {
  outline: none !important;
}

.App {
  text-align: center;
}

:root {
  --amplify-background-color: #83A598;
  --amplify-primary-color: #84545D;
  --amplify-primary-contrast: #1D2021;
  --amplify-primary-tint: #83A598;
  --amplify-primary-shade: #83A598;
  --amplify-secondary-color: #1D2021;
  --amplify-secondary-contrast: #83A598;
  --amplify-tertiary-color: #83A598;
  --amplify-grey: #1D2021;
  --amplify-light-grey: #83A598;
  --amplify-white: #83A598;
  --amplify-red: #83A598;
}

amplify-authenticator {
  --container-height: 5em
}